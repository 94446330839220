export {}
declare global {
	interface Window { dataLayer: any[]; }
}

window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}

function feoCookiesProcessEventDefault(event: CustomEvent) {
	window.dataLayer.push({'event': event.type });
}

// FIRST AJAX LOAD OF COOKIE BAR+WINDOW AFTER PAGE LOADS
window.addEventListener("feoCookiesFirstLoad", feoCookiesProcessEventDefault);

// BAR DISPLAYED (its not visible by default)
window.addEventListener("feoCookiesBarDisplayed", feoCookiesProcessEventDefault);

// COOKIE SETTINGS WINDOW OPENED
window.addEventListener("feoCookiesWindowOpened",feoCookiesProcessEventDefault);

// COOKIE SETTINGS WINDOW CLOSED
window.addEventListener("feoCookiesWindowClosed", feoCookiesProcessEventDefault);

// ALL COOKIES SET TO "ACCEPTED"
window.addEventListener("feoCookiesAcceptedAll", feoCookiesProcessEventDefault);

// ALL COOKIES SET TO "REJECTED"
window.addEventListener("feoCookiesRejectedAll", feoCookiesProcessEventDefault);

// COOKIES SETTINGS UPDATED (this gets called even both when "save settings" or "accept all" is presed)
window.addEventListener("feoCookiesSettingsUpdated", feoCookiesProcessEventDefault);
window.addEventListener("feoCookiesSettingsUpdated", (event: CustomEvent) => {
	const cookies = event.detail.feoCookies.cookies;
	const keys = Object.keys(cookies);

	let consents = {};

	for (let key of keys) {
		consents[key] = cookies[key].accepted ? 'granted' : 'denied';
	}

	//@ts-ignore
	gtag('consent', 'update', consents);
	window.dataLayer.push({
		'event': 'update_consent',
	});
});
